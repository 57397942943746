*, *:before, *:after {
  box-sizing:inherit;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body {
  font-family: "Inter", sans-serif
}

strong {
  font-weight: 600;
}

header a svg {
  height: 16px;
  margin-right: 4px;
  position: relative;
  top: -1px;
}

aside {
  display: inline-block;
}

section {
  margin-bottom: 40px;
  margin-top: 0;
}

@media (min-width: 768px) {
  section {
    margin-bottom: 80px;
    margin-top: 24px;
  }
}

section h2,
section h3 {
  margin-bottom: 16px;
}

p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
  }
}

pre {
  background-color: #F8F9FA !important
}

code {
  font-size: 14px !important;
  line-height: 21px !important
}